//libs
import gsap from "gsap"


import * as THREE from "three";

const vertexShader = `precision highp float;
    attribute vec2 position;
    void main() {
      // Look ma! no projection matrix multiplication,
      // because we pass the values directly in clip space coordinates.
      gl_Position = vec4(position, 1.0, 1.0);
    }`;

const fragmentShader = `precision highp float;
    uniform sampler2D uScene;
    uniform vec2 uResolution;

    void main() {
        vec2 uv = gl_FragCoord.xy / uResolution.xy;
        vec3 color = vec3(uv, 1.0);
        uv.x += sin(uv.x*40.0)/50.0;

        color = texture2D(uScene, uv).rgb;

        gl_FragColor = vec4(color, 1.0);
    }`;

const fragmentShader2 = `precision highp float;
    uniform sampler2D uScene;
    uniform vec2 uResolution;
    uniform float uTranslate;

    void main() {
        vec2 uv = gl_FragCoord.xy / uResolution.xy;
        vec3 color = vec3(uv, 1.0);
        uv.y += sin(uv.y*10.0 + uTranslate)/15.0;

        color = texture2D(uScene, uv).rgb;

        gl_FragColor = vec4(color, 1.0);
    }`;

const fragmentShader3 = `precision highp float;
    uniform sampler2D uScene;
    uniform vec2 uResolution;
    uniform float uTranslate;
    uniform float uIntensite;

    void main() {
        vec2 uv = gl_FragCoord.xy / uResolution.xy;
        vec3 color = vec3(uv, 1.0);
        uv.y += sin(uv.y*14.0 + uTranslate) * uIntensite;

        color = texture2D(uScene, uv).rgb;

        gl_FragColor = vec4(color, 1.0);
    }`;

export class Webgl {

    constructor(pos) {
        this.pos = pos;
    }

    init404() {
        this.W4 = document.querySelector('.webgl404').offsetWidth;
        this.H4 = document.querySelector('.webgl404').offsetHeight;
        this.step = 0;
        this.array4 = [];

        this.scene4 = new THREE.Scene();
        this.ratio = this.W4/this.H4;
        this.scene4.background = new THREE.Color(0x09090A )

        this.camera4 = new THREE.OrthographicCamera(-this.ratio, this.ratio, 1, -1, -1, 1);

        this.renderer4 = new THREE.WebGLRenderer({antialias:true});

        this.renderer4.setSize( this.W4, this.H4 );
        this.renderer4.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        // this.renderer4.setPixelRatio(1);
        document.querySelector('.webgl404').appendChild( this.renderer4.domElement );

        if(this.ratio < 1){
            this.ratio = this.ratio * 3;
        }

        const widthGeo = this.ratio * 0.58;  
        this.heightGeo = this.ratio * 4.71;
        // console.log(heightGeo)
        const geometry = new THREE.PlaneGeometry(widthGeo , this.heightGeo);
        const text = new THREE.TextureLoader().load('img/404/col1.webp',() => {
            this.step++;
            this.testStep();
        });
        const text2 = new THREE.TextureLoader().load('img/404/col2.webp',() => {
            this.step++;
            this.testStep();
        });
        const text3 = new THREE.TextureLoader().load('img/404/col3.webp',() => {
            this.step++;
            this.testStep();
        });

        const material = new THREE.MeshBasicMaterial({map:text});
        const material2 = new THREE.MeshBasicMaterial({map:text2});
        const material3 = new THREE.MeshBasicMaterial({map:text3});

        this.col1 = new THREE.Mesh(geometry, material);
        this.col1.position.set(-this.ratio * 0.71, 1, 0); //0.405 // 1.21

        this.col2 = new THREE.Mesh(geometry, material2);
        this.col2.position.set(-this.ratio * 0.085, 0.5, 0);

        this.col3 = new THREE.Mesh(geometry, material3);
        this.col3.position.set(this.ratio * 0.54, 0, 0);

        this.col4 = new THREE.Mesh(geometry, material);
        this.col4.position.set(this.ratio * 1.165, -0.5, 0);

        this.scene4.add(
            this.col1,
            this.col2,
            this.col3,
            this.col4 
        );

        this.array4.push(this.col1, this.col2, this.col3, this.col4);

        this.y1Set = gsap.quickSetter(this.col1.position, "y");
        this.y2Set = gsap.quickSetter(this.col2.position, "y");
        this.y3Set = gsap.quickSetter(this.col3.position, "y");
        this.y4Set = gsap.quickSetter(this.col4.position, "y");

        this.posY1 = 0;
        this.posY2 = 0.5;
        this.posY3 = -0.5;
        this.posY4 = 0.2;

        this.varia1 = 0;
        this.varia2 = 0;
        this.varia3 = 0;
        this.varia4 = 0;

        this.deltaY = 0;
        this.dTrans = 0;

        // POST
        this.constructorPost(this.renderer4);

        // post pro
        this.inten = gsap.quickSetter(this.materialP.uniforms.uIntensite, "value");
        this.trans = gsap.quickSetter(this.materialP.uniforms.uTranslate, "value");

        this.raf404();
    }

    testStep() {
        if(this.step == 3){
            gsap.to('.webgl404', {
                opacity:0.75,
                duration:1,
                delay:0.2,
                ease:'power2.inOut'
            })
        }
    }

    wheel()
    {
        window.addEventListener('wheel', this.ref = (e) => {
            // if(this.leRaf === null){
            //  this.playRAF();
            // }

            // console.log('wheel init tourne');

            this.deltaY = e.deltaY;
            
            window.clearTimeout( this.isWheeling );
            this.isWheeling = setTimeout( (e) => {
                this.deltaY = 0;
            }, 66);
        }, {passive: true});
    }

    touchmove()
    {
        let oldTouch = 0, deltaTouch = 0, firstMove = true;
        window.addEventListener('touchmove', this.ref = (e) => {
            
            deltaTouch = e.touches[0].clientY - oldTouch;
            if(!firstMove){
                this.deltaY = -deltaTouch*5;
            }
            firstMove = false;

            oldTouch = e.touches[0].clientY;

            window.clearTimeout( this.isWheeling );
            this.isWheeling = setTimeout( (e) => {
                this.deltaY = 0;
                deltaTouch = 0;
                oldTouch = 0;
                firstMove = true;
            }, 66);
        }, {passive: true})
    }
    unwheel()
    {
        window.removeEventListener('wheel', this.ref);
    }
    untouchmove()
    {
        window.removeEventListener('touchmove', this.ref);
    }

    playTicker404() {

        const gDt = gsap.ticker.deltaRatio();
        const dt = 1.0 - Math.pow(1.0 - 0.1, gDt);//0.1 = speed

        this.y1Set(this.posY1);
        this.y2Set(this.posY2);
        this.y3Set(this.posY3);
        this.y4Set(this.posY4);

        window.scrollTo(0,0);

        // cas glitch tv
        if(this.varia1/2 > 0.07){
            this.inten(0.07)
        }else if(this.varia1/2 < -0.07){
            this.inten(-0.07)
        }else{
            this.inten(this.varia1/2)
        }
        this.trans(-this.dTrans*4);
        // console.log(this.varia1/3);


        this.varia1 += (this.deltaY/900 - this.varia1) * dt;
        this.posY1 += this.varia1 + gDt/900;
        this.dTrans += this.varia1;

        this.varia2 += (this.deltaY/700 - this.varia2) * dt;
        this.posY2 += this.varia2 + gDt/800;

        this.varia3 += (this.deltaY/1200 - this.varia3) * dt;
        this.posY3 += this.varia3 + gDt/1200;

        this.varia4 += (this.deltaY/850 - this.varia4) * dt;
        this.posY4 += this.varia4 + gDt/850;

        if(this.col1.position.y > (this.heightGeo - 2) / 2) {
            this.posY1 = this.posY1 - this.heightGeo/2;
        }
        if(this.col1.position.y < -(this.heightGeo - 2) / 2) {
            this.posY1 = this.posY1 + this.heightGeo/2;
        }

        if(this.col2.position.y > (this.heightGeo - 2) / 2) {
            this.posY2 = this.posY2 - this.heightGeo/2;
        }
        if(this.col2.position.y < -(this.heightGeo - 2) / 2) {
            this.posY2 = this.posY2 + this.heightGeo/2;
        }

        if(this.col3.position.y > (this.heightGeo - 2) / 2) {
            this.posY3 = this.posY3 - this.heightGeo/2;
        }
        if(this.col3.position.y < -(this.heightGeo - 2) / 2) {
            this.posY3 = this.posY3 + this.heightGeo/2;
        }

        if(this.col4.position.y > (this.heightGeo - 2) / 2) {
            this.posY4 = this.posY4 - this.heightGeo/2;
        }
        if(this.col4.position.y < -(this.heightGeo - 2) / 2) {
            this.posY4 = this.posY4 + this.heightGeo/2;
        }

        this.renderPost(this.scene4, this.camera4);
        //this.renderer4.render(this.scene4, this.camera4);
    }

    initJoin() {

        this.WJ = document.querySelector('.innerCan').offsetWidth;
        this.HJ = document.querySelector('.innerCan').offsetHeight;

        this.sceneJ = new THREE.Scene();

        this.cameraJ = new THREE.OrthographicCamera(-this.WJ/this.HJ, this.WJ/this.HJ, 1, -1, -1, 1);

        this.rendererJ = new THREE.WebGLRenderer({antialias:true, alpha:true});

        this.rendererJ.setSize( this.WJ, this.HJ );
        // this.renderer.setPixelRatio(0.5)
        this.rendererJ.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        document.querySelector('.innerCan').appendChild( this.rendererJ.domElement );

        const geometry = new THREE.PlaneGeometry(2, 2);
        let url = 'img/cards/08.jpg';
        if (document.body.classList.contains('about')){
            url = 'img/about/team02.webp';
        }
        const text = new THREE.TextureLoader().load(url,() => {

            //alert();
            //this.rendererJ.render(this.sceneJ, this.cameraJ);
            gsap.to('.innerCan', {opacity:1, delay:0.4, duration:1});
            if(document.querySelector('.innerCan .volet') != null){
                gsap.to('.innerCan .volet', {scaleY:0, delay:0.4, duration:2, ease:"power4.out"});
            }
            gsap.to('.innerCan canvas, .looking p', {y:0, delay:0.4, stagger:0.2, opacity:1, duration:2, ease:"power4.out"});

            this.constructorPost(this.rendererJ);
            this.renderPost(this.sceneJ, this.cameraJ);

        });
        const material = new THREE.MeshBasicMaterial({map:text});

        const mesh = new THREE.Mesh(geometry, material);
        this.sceneJ.add(mesh);
        
    }

    init() {

        this.W = window.innerWidth;
        this.H = document.getElementById('footer2').offsetHeight;

        this.table = [];
        this.zIndex = 0;
        

        // ticker
        this.speed = 0.1;
        this.varX = 0;
        this.varY = 0;

        this.oldX = 0;

        this.incrDist = 0;
        this.currentItem = 0;


        let chem = "";
        if(document.body.classList.contains('single')){
            chem = "../";
        }
        const text01 = new THREE.TextureLoader().load(chem + 'img/cards/01.webp', () => {
            this.renderPost(this.scene, this.camera);
        });
        const text02 = new THREE.TextureLoader().load(chem + 'img/cards/02.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube02.visible = false;
        });
        const text03 = new THREE.TextureLoader().load(chem + 'img/cards/03.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube03.visible = false;
        });
        const text04 = new THREE.TextureLoader().load(chem + 'img/cards/04.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube04.visible = false;
        });
        const text05 = new THREE.TextureLoader().load(chem + 'img/cards/05.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube05.visible = false;
        });
        const text06 = new THREE.TextureLoader().load(chem + 'img/cards/06.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube06.visible = false;
        });
        const text07 = new THREE.TextureLoader().load(chem + 'img/cards/07.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube07.visible = false;
        });
        const text08 = new THREE.TextureLoader().load(chem + 'img/cards/08.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube08.visible = false;
        });
        const text09 = new THREE.TextureLoader().load(chem + 'img/cards/09.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube09.visible = false;
        });
        const text10 = new THREE.TextureLoader().load(chem + 'img/cards/10.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube10.visible = false;
        });
        const text11 = new THREE.TextureLoader().load(chem + 'img/cards/11.webp', () => {
            this.renderPost(this.scene, this.camera);
            this.cube11.visible = false;
        });

        this.scene = new THREE.Scene();
        // this.scene.background = new THREE.Color( 0x09090A );
        this.scene.background = new THREE.Color( 0x000000 );

        this.camera = new THREE.OrthographicCamera(-this.W/this.H, this.W/this.H, 1, -1, -1, 1);

        this.renderer = new THREE.WebGLRenderer({antialias:true});//alpha:true, 

        this.renderer.setSize( this.W, this.H );
        // this.renderer.setPixelRatio(0.5)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        document.getElementById('webgl').appendChild( this.renderer.domElement );

        const geometry = new THREE.PlaneGeometry(1, 1);

        const material01 = new THREE.MeshBasicMaterial( { map: text01 } );
        const material02 = new THREE.MeshBasicMaterial( { map: text02 } );
        const material03 = new THREE.MeshBasicMaterial( { map: text03 } );
        const material04 = new THREE.MeshBasicMaterial( { map: text04 } );
        const material05 = new THREE.MeshBasicMaterial( { map: text05 } );
        const material06 = new THREE.MeshBasicMaterial( { map: text06 } );
        const material07 = new THREE.MeshBasicMaterial( { map: text07 } );
        const material08 = new THREE.MeshBasicMaterial( { map: text08 } );
        const material09 = new THREE.MeshBasicMaterial( { map: text09 } );
        const material10 = new THREE.MeshBasicMaterial( { map: text10 } );
        const material11 = new THREE.MeshBasicMaterial( { map: text11 } );

        this.cube01 = new THREE.Mesh( geometry, material01 );
        this.cube02 = new THREE.Mesh( geometry, material02 );
        this.cube03 = new THREE.Mesh( geometry, material03 );
        this.cube04 = new THREE.Mesh( geometry, material04 );
        this.cube05 = new THREE.Mesh( geometry, material05 );
        this.cube06 = new THREE.Mesh( geometry, material06 );
        this.cube07 = new THREE.Mesh( geometry, material07 );
        this.cube08 = new THREE.Mesh( geometry, material08 );
        this.cube09 = new THREE.Mesh( geometry, material09 );
        this.cube10 = new THREE.Mesh( geometry, material10 );
        this.cube11 = new THREE.Mesh( geometry, material11 );

        //this.cube01
        // this.cube02.visible = false;
        // this.cube03.visible = false;
        // this.cube04.visible = false;
        // this.cube05.visible = false;
        // this.cube06.visible = false;
        // this.cube07.visible = false;
        // this.cube08.visible = false;
        // this.cube09.visible = false;
        // this.cube10.visible = false;
        // this.cube11.visible = false;

        this.cube01.material.transparent = this.cube02.material.transparent = this.cube03.material.transparent = this.cube04.material.transparent = this.cube05.material.transparent = this.cube06.material.transparent = this.cube07.material.transparent = this.cube08.material.transparent = this.cube09.material.transparent = this.cube10.material.transparent = this.cube11.material.transparent = true;

        this.table.push(this.cube01, this.cube02, this.cube03, this.cube04, this.cube05, this.cube06, this.cube07, this.cube08, this.cube09, this.cube10, this.cube11);

        this.scene.add( this.cube01, this.cube02, this.cube03, this.cube04, this.cube05, this.cube06, this.cube07, this.cube08, this.cube09, this.cube10, this.cube11 );

        this.mX01 = gsap.quickSetter(this.cube01.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY01 = gsap.quickSetter(this.cube01.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX02 = gsap.quickSetter(this.cube02.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY02 = gsap.quickSetter(this.cube02.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX03 = gsap.quickSetter(this.cube03.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY03 = gsap.quickSetter(this.cube03.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX04 = gsap.quickSetter(this.cube04.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY04 = gsap.quickSetter(this.cube04.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX05 = gsap.quickSetter(this.cube05.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY05 = gsap.quickSetter(this.cube05.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX06 = gsap.quickSetter(this.cube06.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY06 = gsap.quickSetter(this.cube06.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX07 = gsap.quickSetter(this.cube07.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY07 = gsap.quickSetter(this.cube07.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX08 = gsap.quickSetter(this.cube08.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY08 = gsap.quickSetter(this.cube08.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX09 = gsap.quickSetter(this.cube09.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY09 = gsap.quickSetter(this.cube09.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX10 = gsap.quickSetter(this.cube10.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY10 = gsap.quickSetter(this.cube10.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mX11 = gsap.quickSetter(this.cube11.position, "x"); // "ajouter , 'px' pour quand c'est en pixel"
        this.mY11 = gsap.quickSetter(this.cube11.position, "y"); // "ajouter , 'px' pour quand c'est en pixel"
        
        this.constructorPost(this.renderer);
    }

    fondHome() {
        this.scene.background = new THREE.Color( 0x131313 );
    }
    fondAutreQueHome() {
        this.scene.background = new THREE.Color( 0x000000 );
    }

    //-->
    raf() {
        this.addTicker = () => {
            this.playTicker();
        }
        gsap.ticker.add(this.addTicker);
    }

    killRaf() {
        gsap.ticker.remove(this.addTicker);
    }
    //-->

    //-->
    raf404() {
        this.addTicker404 = () => {
            this.playTicker404();
        }
        gsap.ticker.add(this.addTicker404);
    }
    killRaf404() {
        gsap.ticker.remove(this.addTicker404);
    }

    constructorPost(renderer) {
        this.rendererP = renderer;
        this.sceneP = new THREE.Scene();
        
        this.dummyCameraP = new THREE.OrthographicCamera();
        this.geometryP = new THREE.BufferGeometry();

        // Triangle expressed in clip space coordinates
        const vertices = new Float32Array([
          -1.0, -1.0,
          3.0, -1.0,
          -1.0, 3.0
        ]);

        this.geometryP.setAttribute('position', new THREE.BufferAttribute(vertices, 2));

        this.resolutionP = new THREE.Vector2();
        this.rendererP.getDrawingBufferSize(this.resolutionP);

        this.targetP = new THREE.WebGLRenderTarget(this.resolutionP.x, this.resolutionP.y, {
            format: THREE.RGBFormat,
            stencilBuffer: false,
            depthBuffer: true,
        });

        // de haut en bas
        if(renderer == this.renderer){
            this.materialP = new THREE.RawShaderMaterial({
                fragmentShader,
                vertexShader,
                uniforms: {
                    uScene: { value: this.targetP.texture },
                    uResolution: { value: this.resolutionP },
                },
            });
        }else if(renderer == this.rendererJ){
            this.materialP = new THREE.RawShaderMaterial({
                fragmentShader:fragmentShader2,
                vertexShader,
                uniforms: {
                    uScene: { value: this.targetP.texture },
                    uResolution: { value: this.resolutionP },
                    uTranslate: {value: 0.},
                },
            });
        }else if(renderer == this.renderer4){
            this.materialP = new THREE.RawShaderMaterial({
                fragmentShader:fragmentShader3,
                vertexShader,
                uniforms: {
                    uScene: { value: this.targetP.texture },
                    uResolution: { value: this.resolutionP },
                    uTranslate: {value: 0.},
                    uIntensite: {value: 0.}
                },
            });
        }

        // TODO: handle the resize -> update uResolution uniform and this.target.setSize()

        this.triangleP = new THREE.Mesh(this.geometryP, this.materialP);
        // Our triangle will be always on screen, so avoid frustum culling checking
        this.triangleP.frustumCulled = false;
        this.sceneP.add(this.triangleP);
    }

    renderPost(scene, camera) {
        this.rendererP.setRenderTarget(this.targetP);
        this.rendererP.render(scene, camera);
        this.rendererP.setRenderTarget(null);
        this.rendererP.render(this.sceneP, this.dummyCameraP);
    }


    playTicker(){
        const dt = 1.0 - Math.pow(1.0 - this.speed, gsap.ticker.deltaRatio());

        const variationX = gsap.utils.mapRange(
            0,
            this.W,
            -this.W/this.H,
            this.W/this.H,
            this.pos.x
        );
        const variationY = gsap.utils.mapRange(
            0,
            this.H,
            1,
            -1,
            this.pos.y - document.getElementById('webgl').getBoundingClientRect().top
        );

        this.varX += (variationX - this.varX) * dt;
        this.varY += (variationY - this.varY) * dt;


        if(this.currentItem == 0){
            this.mX01(this.varX);
            this.mY01(this.varY);
        }else if(this.currentItem == 1){
            this.mX02(this.varX);
            this.mY02(this.varY);
        }else if(this.currentItem == 2){
            this.mX03(this.varX);
            this.mY03(this.varY);
        }else if(this.currentItem == 3){
            this.mX04(this.varX);
            this.mY04(this.varY);
        }else if(this.currentItem == 4){
            this.mX05(this.varX);
            this.mY05(this.varY);
        }else if(this.currentItem == 5){
            this.mX06(this.varX);
            this.mY06(this.varY);
        }else if(this.currentItem == 6){
            this.mX07(this.varX);
            this.mY07(this.varY);
        }else if(this.currentItem == 7){
            this.mX08(this.varX);
            this.mY08(this.varY);
        }else if(this.currentItem == 8){
            this.mX09(this.varX);
            this.mY09(this.varY);
        }else if(this.currentItem == 9){
            this.mX10(this.varX);
            this.mY10(this.varY);
        }else if(this.currentItem == 10){
            this.mX11(this.varX);
            this.mY11(this.varY);
        }

        this.renderPost(this.scene, this.camera);

        this.incrDist += Math.abs(this.oldX - this.varX);

        if(this.incrDist > 0.25){
            this.incrDist = 0;
            
            const el = this.table[this.currentItem];
            gsap.to(el.position, {
                duration:0.8,
                y: -1.5,
                x: this.oldX - this.varX,
                ease:"power2.in",
                onComplete:() => {
                    el.visible = false;
                }
            })

            gsap.to(el.scale, {
                duration:0.8,
                x: 0.7,
                y: 0.7,
                ease:"power2.in"
            })
            
            this.currentItem++
            if(this.currentItem >= 11){
                this.currentItem = 0;
            }

            gsap.killTweensOf(this.table[this.currentItem].position);
            gsap.killTweensOf(this.table[this.currentItem].scale);
            
            this.table[this.currentItem].position.x = this.table[this.currentItem].position.y = 0;
            this.table[this.currentItem].scale.x = this.table[this.currentItem].scale.y = 1;
            this.table[this.currentItem].visible = true;
           
            this.zIndex++;
            this.table[this.currentItem].renderOrder = this.zIndex;
        }
        this.oldX = this.varX;
        
    }

    
}