// libs
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"

// modules
// import {GSAPScroll} from "./GSAPScroll";
import {indexInParent, isMobile} from "./utils";

// constants
import {lenis, animsEntree, killSingle} from './constants';



export class Transition
{
	constructor(elmt) {
		this.currentStep = 0;
        this.allSteps = 2; //3

        this.newClass;
        this.newTitle;
        this.newDOM;

        this.animEnCours = true;


        // chargement img
        // this.len = 0;
        // this.counter = 0;
        // this.tableauImgs = [];
        // this.ancienLen = 0;
        // this.uneFois = true;
    }

    start (url) {

        //this.len = 0;
        //this.counter = 0;
        this.animEnCours = true;

    	this.animationSortie();
    	this.changementDOM(url);
    }

    // STEP
    animationSortie() {

        //-- KILL RAFSS
        window.webgl.killRaf();

        if(window.webgl404 != undefined){
            if(!isMobile()){
                window.webgl404.unwheel();
            }else{
                window.webgl404.untouchmove();
            }
            window.webgl404.killRaf404();
        }
        //--
        
        if(
            document.getElementById('projets').classList.contains('actif')
            || document.getElementById('projets').classList.contains('actifFooter')
        ){

            document.getElementById('explore').classList.remove('actif');

            //
            // scroll.destroy();
            if(lenis !== null){
                lenis.stop()
            }

            if(document.getElementById('projets').classList.contains('actifFooter')){

                document.querySelector('.fiche.current .actif').classList.remove('actif');
                
                if(ScrollTrigger.getById("projetsY") != undefined){
                    ScrollTrigger.getById("projetsY").kill(false); // false pour pas que ça se kill à la position
                    ScrollTrigger.getById("innerExpScale").kill(false); // false pour pas que ça se kill à la position
                }
                
                gsap.to('.content', {
                    y: -window.innerHeight/2 + "px",
                    opacity:0,
                    ease:"power4.inOut",
                    duration:0.8
                })
                gsap.to('#projets', {
                    duration:0.8,
                    y:'0%',
                    ease:"power4.inOut"
                }) 

                if(window.innerWidth < 768){
                    
                    document.getElementById('projets').style.overflow = "visible";

                    let distManqu = document.getElementById('main').offsetHeight - window.innerHeight - window.scrollY;

                    gsap.to('.nextP, .toNext', {
                        opacity:0,
                        duration:1
                    })

                    gsap.to('#projets', {
                        duration:1.6,
                        y: -window.innerHeight + 400 - distManqu + "px", // - document.getElementById('projets').getBoundingClientRect().top
                        ease:"power4.inOut"
                    })
                }
            }
            
            gsap.to('.innerExp', {
                duration:0.6,
                perspectiveOrigin: '50% 50%', 
                ease:"power4.inOut", 
                onComplete:function(){
                    
                    gsap.to('.fiche', { 
                        duration:0.8,
                        rotationY: "0deg",
                        rotationX: "0deg",
                        z:'0px', // ajoute
                        ease:"power4.inOut"
                    });
                }
            });

            gsap.to('.liste, .innerBtn, .innerC', {
                duration:0.4,
                opacity:0
            });

            gsap.to('.innerExp', {
                duration:1.3, //1
                delay:0.4,
                ease:"power4.inOut",
                y:'0%',
                scale:1,
                onComplete:() => {
                    //scroll.destroy();
                    if(lenis !== null){
                        lenis.stop()
                    }
                    // console.log('incr');
                    this.incrementStep();
                    cancelAnimationFrame(window.leRafPush);
                    // console.log('incrStep -> anim sortie OK');

                    gsap.set('.liste, .innerBtn, .innerC', {clearProps:'all'});

                    this.killAll();
                }
            });

            // RESTE -> le faire que sur fiche actuelle ?
            gsap.to('.current .h1Single .haut', {
                duration:1.3, 
                y:'-100%',
                delay:0.4,
                ease:"power4.inOut"
            })
            gsap.to('.current .h1Single .bas', {
                duration:1.3, 
                y:'0%',
                delay:0.4,
                ease:"power4.inOut",
                onComplete:() => {
                    gsap.set('.current .h1Single .haut', {y:'0%'})
                    gsap.set('.current .h1Single .bas', {y:'100%'})
                }
            })

        }else{
            gsap.to('#main', {opacity:0, onComplete: () => {
                // scroll.destroy();
                if(lenis !== null){
                    lenis.stop()
                }
                // console.log('incr');
                this.incrementStep();
                // console.log('incrStep -> anim sortie OK');
                cancelAnimationFrame(window.leRafPush); 

                this.killAll();
            }})
        }

        if(document.body.classList.contains('home')){
            window.removeEventListener('mousemove', window.mMove)
        }

        if(document.body.classList.contains('single')){
            killSingle()
        }

        
    }


    killAll() {
        let triggers = ScrollTrigger.getAll();
        triggers.forEach( trigger => {
          trigger.kill();
        });
    }

    // STEP
    changementDOM(url) {
    	this.ajaxPromise(url).then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            

            let liste_class = doc.body.getAttribute('class');
            
            // maj titre de la page
            this.newTitle = doc.querySelector('title').innerHTML;
			this.newClass = doc.body.getAttribute('class');
            this.newDOM = doc.getElementById('main').innerHTML;

            // console.log('incr');
			this.incrementStep();
            // console.log('incrStep -> nouveau DOM OK');



            // if(doc.querySelectorAll('.preload').length !== 0){
            //     this.len = doc.querySelectorAll('.preload').length
            //     doc.querySelectorAll('.preload').forEach(x => {

            //         let limg = new Image();
            //         limg.src = x.getAttribute('src');

            //         // let doneLoading = limg.complete;
            //         // console.log(doneLoading, x);

            //         limg.onload = () => {
            //             console.log('gud', limg.src)
            //             this.counter++;
            //             this.uneImgChargee();
            //         }
            //     });
            // }



            // if(doc.querySelectorAll('.preload').length !== 0){

            //     var imgsProchainePage = [];
            //     doc.querySelectorAll('.preload').forEach(x => {
            //         imgsProchainePage.push(x.getAttribute('src'));
            //     });

            //     // console.log('une fois');
            //     this.chargementImages(imgsProchainePage);
            // }else{
            //     // console.log('else une fois');

            //     this.incrementStep();
            //     console.log('incrStep -> pas dimages a preload');
            // }
        });

        //history.pushState({}, '', url);
    }

    // uneImgChargee() {
    //     console.log(this.counter, this.len)
    //     if(this.counter == this.len){
    //         this.incrementStep();
    //         console.log('incrStep -> images chargees');
    //     }
    // }

    // ALL STEPS COMPLETE
    animationEntree(titre, classes, DOM) {
        //reset
        // console.log('entree');
        window.scrollTo(0,0);

		this.currentStep = 0;
        this.uneFois = true;

    	// maj titre, class, DOM page
        document.title = this.newTitle;
		document.body.setAttribute('class', this.newClass);
        document.getElementById('main').innerHTML = this.newDOM;

        // document.querySelectorAll('.preload').forEach(x => {
        //     console.log(x.getAttribute('src'));
        // });

        // maj liens single proj
        if(document.body.classList.contains('single')){
            // console.log('oui')
            gsap.to('header, .premierTexte', {opacity:1})

            // if(isMobile()){
            //     document.querySelector('#main .hero').style.height = window.innerHeight + "px";
            // }

            document.querySelectorAll('#projets .fiche').forEach(fiche => {
                fiche.setAttribute('href', fiche.getAttribute('data-url'));
            })

            document.querySelector('.lienHome').setAttribute('href', '../');
            document.querySelector('.toAbout').setAttribute('href', '../about');
            document.querySelector('.toJoin').setAttribute('href', '../join-the-club');
        }else{
            document.querySelectorAll('#projets .fiche').forEach(fiche => {
                fiche.setAttribute('href', fiche.getAttribute('data-url-basic'));
            })
        }

        if(
            document.getElementById('projets').classList.contains('actif')
            || document.getElementById('projets').classList.contains('actifFooter')
        ){
            gsap.set('#main', {opacity:1});
            document.getElementById('projets').classList.remove('actif', 'actifFooter');
            
            if(window.innerWidth < 768){
                gsap.set('#projets', {y: "0px"})
                gsap.set('.nextP, .toNext', {
                    clearProps: "all"
                })
                document.getElementById('projets').style.overflow = "hidden";
            }

            document.body.classList.add('fromFiche');

            // gsap.to('#projets', {clearProps:'all'});
            // gsap.to('.innerExp', {clearProps:'all'});
            // gsap.to('.fiche', {clearProps:'all'});
        }
        
        //scroll.scrollTop = scroll.current = 0;
        if(lenis !== null){
            lenis.scrollTo(0, { immediate: true })
        }

        gsap.to('#main', {opacity:1, duration:0.4, onComplete: () => {
        //gsap.set('#main', {opacity:1});

            // if(!isMobile()){
            //     scroll.resize();
            //     scroll.init();
            //     scroll.wheel();
            // }
            if(lenis !== null){
                lenis.start()
            }
            this.animEnCours = false;
            // console.log('anim en cours passe à false')

            animsEntree();

            //ScrollTrigger.refresh();
        }});
        
    }


    //
    // chargementImages(tableau){

    //     this.ancienLen = this.len;
    //     // pour tous les .preload du dom
    //     tableau.forEach((element) => {

    //         if(this.tableauImgs.includes(element) === false){
    //             this.tableauImgs.push(element);
    //             this.len++;

    //             //console.log(element);
    //             window['myImage'+this.len] = new Image();
    //             window['myImage'+this.len].src = element;
                
    //             window['myImage'+this.len].addEventListener( 'load', this.incrementCounter(), false );

    //         }

    //         // console.log(this.tableauImgs);
    //     });

    //     if(this.ancienLen === this.len){
    //         // console.log('pas de nouvelles images à charger');
    //         // console.log('length', this.tableauImgs.length);
    //         // console.log('INCRE STEPPPPPP AUTRE')
    //         this.incrementStep();
    //         console.log('incrStep -> pas de nouvelles images à charger');
    //     }
    // }

    // incrementCounter() {
    //     this.counter++;
    //     if ( this.counter === this.len && this.uneFois ) {
    //         // console.log('toutes les nouvelles images sont chargées');
    //         // console.log('length', this.tableauImgs.length);
    //         // console.log('INCRE STEPPPPPP FIN')
    //         this.incrementStep();
    //         console.log('incrStep -> nouvelles images à chargées');
    //         this.uneFois = false;
    //     }
    // }




    //
    //
    // util class
    //
    //
    incrementStep() {
    	this.currentStep++;
        // console.log(this.currentStep);
    	if(this.currentStep == this.allSteps) {
            // console.log('ça part');
    		this.animationEntree();
    	}
    }

    ajaxPromise (url) {
		return new Promise((resolve) => { //, reject
			const xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.onload = () => resolve(xhr.responseText);
			// xhr.onerror = () => reject(xhr.statusText);
			xhr.send();
		});
	}
}