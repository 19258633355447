//libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"


// modules
// import {GSAPScroll} from "GSAPScroll.js"
import Lenis from '@studio-freight/lenis'
import {simulateClick, isMobile} from "modules/utils"
import {Webgl} from "modules/Webgl"
// import YouTubeToHtml5 from "@thelevicole/youtube-to-html5-loader"

import Swiper, { Navigation } from 'swiper';



// export const scroll = new GSAPScroll('#garciScroll')
export let lenis = !isMobile() ? new Lenis({
    lerp: 0.06
}) : null;
if(lenis){
    lenis.stop()
    function raf(time) {
        lenis.raf(time)
        requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)
}

let observer, st, st2
export const killSingle = function() {
    if(observer !== undefined){
        observer.disconnect()
    }
    st && st.kill()
    st2 && st2.kill()
}

export const animsEntree = function() {

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // console.log(isSafari);

    // cool
    if(document.querySelectorAll('.preload').length !== 0){
        document.querySelectorAll('.preload').forEach(x => {

            let limg = new Image();
            limg.src = x.getAttribute('src');

            limg.onload = () => {
                // scroll.resize();

                // if(document.body.classList.contains('single') && window.innerWidth < 768){
                //     document.getElementById('projets').style.top = document.getElementById('main').offsetHeight - 400 + "px";
                // }

                ScrollTrigger.refresh();
            }
        });
    }

    if(document.querySelectorAll('video').length !== 0){

        document.querySelectorAll('video').forEach(x => {
            x.onloadeddata = function() {
                // scroll.resize();
                ScrollTrigger.refresh();
                // console.log('uuuu')
            };
        });
    }

    document.getElementById('footer2').addEventListener('mouseenter', () => {
        // console.log('iii');
        window.webgl.killRaf();
        window.webgl.raf();
        gsap.killTweensOf('#webgl canvas');
        gsap.to('#webgl canvas', {
            duration:1,
            opacity:0.75
        })
    })
    document.getElementById('footer2').addEventListener('mouseleave', () => {
        // console.log('oooo');
        gsap.to('#webgl canvas', {
            duration:1,
            opacity:0,
            onComplete:() => {
                window.webgl.killRaf();
            }
        })
    })


    //global
    document.getElementById('webgl').classList.remove('cache');

    if(!document.body.classList.contains('single') && !document.body.classList.contains('p404')){

        ScrollTrigger.create({
            trigger: ".toContact",
            start: "top bottom",
            onToggle: self => {
                if(!self.isActive){
                    document.querySelector('.toContact').classList.remove('actif');
                    //document.getElementById('webgl').classList.add('cache');
                    // window.webgl.killRaf();
                }else{
                    document.querySelector('.toContact').classList.add('actif');
                    //document.getElementById('webgl').classList.remove('cache');
                    // window.webgl.raf();
                }
            }
        });
    } 

    if(document.querySelector('#main .h1 span') !== null){
        gsap.to('#main .h1 span', {
            opacity:1,y:'0%',stagger:0.1, delay:0.2, ease:"power2.out",duration:1
        })
    }

    document.querySelectorAll('#main .h2').forEach(h2 => {
        ScrollTrigger.create({
            trigger: h2,
            start: "top 100%",
            onEnter: () => {
                gsap.to(h2.querySelectorAll('span'), {
                    opacity:1,y:'0%',stagger:0.1,ease:"power2.out",duration:1
                })
            },
            onLeaveBack: () => {
                gsap.killTweensOf(h2.querySelectorAll('span'));
                gsap.set(h2.querySelectorAll('span'), {
                    opacity:0,y:'40%'
                })
            }
        });
    })
    

    // document.querySelectorAll('.h2 span').forEach(span => {
    //     gsap.to(span, {
    //         scrollTrigger:{
    //             trigger:span,
    //             start:'top 75%'
    //         },
    //         opacity:1,
    //         y:'0%',
    //         duration:1,
    //         ease:"power2.out"
    //     })
    // })

    // fond canvas footer
    if(document.body.classList.contains('home')){
        window.webgl.fondHome()
    }else{
        window.webgl.fondAutreQueHome()
    }
    

    // HOME
    if(document.body.classList.contains('home')){

        

        //nouveau hover
        window.items = []
        document.querySelectorAll('.mediaHov').forEach(el => {

            const media = el.querySelector('.media')
            let xTo = gsap.quickTo(media, "x", {duration: 0.6, ease: "power1"}),
                yTo = gsap.quickTo(media, "y", {duration: 0.6, ease: "power1"});

            el.addEventListener('mouseenter', () => {el.classList.add('actif')})
            el.addEventListener('mouseleave', () => {
                el.classList.remove('actif')
                xTo(0)
                yTo(0)
            })

            window.items.push({
                el,
                xTo,
                yTo
            });

        })
        window.addEventListener("mousemove", window.mMove);
        //fin nouveau hover

        gsap.to('.cStag', {
            opacity:1,
            y:0,
            ease:'power3.out',
            duration:1,
            stagger:0.07,
            delay:1.3
        })


        if(window.innerWidth > 767){

            // document.querySelectorAll('video').forEach(vid => {
            //     vid.setAttribute("autoplay", "")
            // })
            
            
            // gsap.to('#roulette', {
            //     scrollTrigger:{
            //         trigger:'.kidding',
            //         start:'top bottom',
            //         end: 'bottom top',
            //         scrub:true,
            //         onEnter:() => {document.getElementById('roulette').classList.add('actif')},
            //         onLeaveBack:() => {document.getElementById('roulette').classList.remove('actif')},
            //         onLeave:() => {document.getElementById('roulette').classList.remove('actif')},
            //         onEnterBack:() => {document.getElementById('roulette').classList.add('actif')},
            //         onUpdate:(self) => {
            //             if(self.progress > 0.5 && !document.querySelector('.antiRota3').classList.contains('actif')){
            //                 document.querySelector('.antiRota3').classList.add('actif')
            //             }else if(self.progress <= 0.5 && document.querySelector('.antiRota3').classList.contains('actif')){
            //                 document.querySelector('.antiRota3').classList.remove('actif')
            //             }
            //         }
            //     },
            //     rotate:"-360deg",
            //     ease:'none'
            // })
            // gsap.to('.antiRota', {
            //     scrollTrigger:{
            //         trigger:'.kidding',
            //         start:'top bottom',
            //         end: 'bottom top',
            //         scrub:true
            //     },
            //     rotate:"360deg",
            //     ease:'none'
            // })

            // if(!isSafari){
            //     document.querySelectorAll('.push').forEach(push => {
            //         push.addEventListener('mouseenter', () => {
            //             push.classList.add('actif');
            //             window.rafPush();
            //         })
            //         push.addEventListener('mouseleave', () => {
            //             cancelAnimationFrame(window.leRafPush);
            //             gsap.to('.push.actif img', {
            //                 rotationY: "0deg", 
            //                 rotationX: "0deg", 
            //                 duration:0.6, 
            //                 ease:"power2.inOut"
            //             })
            //             push.classList.remove('actif');
            //         })
            //     })
            // }

        // if home mobile
        }else {
            //homeVideosDesktopToMobile();
        }

    // about
    }else if(document.body.classList.contains('about')){

        // document.querySelector('.innerPuzzle').style.display = 'block';
        // document.querySelector('.who').style.opacity = '1';

        window.webglAbout = new Webgl();
        window.webglAbout.initJoin();  

        

        gsap.to('.innerCarte', {
            y:0,
            stagger:0.1,
            ease:"power4.out",
            duration:2
        })

        let elmtCarte = '.carteDesk';
        if(window.innerWidth < 768){
            elmtCarte = '.carteMob';
        }
        
        gsap.to(elmtCarte, {
            scrollTrigger:{
                trigger:'.puzzle',
                start:'top top',
                end: 'bottom top',
                scrub:true,
            },
            x:0,y:0,ease:'none'
        });


        ScrollTrigger.create({
            trigger:'.innerPuzzle',
            start:'top top',
            end:'bottom bottom',
            pin:'.bloqueCarte',
        });

        ScrollTrigger.create({
            trigger:'.innerCan',
            start:'top bottom',
            end:'bottom top',
            onUpdate: () => {
                if(window.webglAbout.materialP != undefined){

                    window.webglAbout.materialP.uniforms.uTranslate.value = window.scrollY/100;

                    window.webglAbout.renderPost(window.webglAbout.sceneJ, window.webglAbout.cameraJ);
                }
            }
        });

        // gsap.to('.team', {
        //     scrollTrigger:{
        //         trigger:'.who',
        //         start:'top bottom',
        //         end: 'bottom top',
        //         scrub:true
        //     },
        //     rotation:'-11deg',
        //     y:'0px',
        //     ease:'power2.out'
        // })

        // gsap.to('.fond', {
        //     scrollTrigger:{
        //         trigger:'.who',
        //         start:'top bottom',
        //         end: 'bottom top',
        //         scrub:true,
        //         onUpdate:() => {
        //             if(window.webglAbout.materialP != undefined){

        //                 if(!isMobile()){
        //                     window.webglAbout.materialP.uniforms.uTranslate.value = -scroll.current/100;
        //                 }else{
        //                     window.webglAbout.materialP.uniforms.uTranslate.value = window.scrollY/100;
        //                 }

        //                 window.webglAbout.renderPost(window.webglAbout.sceneJ, window.webglAbout.cameraJ);
        //             }
        //         }
        //     },
        //     rotation:'14deg',
        //     y:'0px',
        //     ease:'power2.out'
        // })
        // const dist = document.querySelector('.cards').clientWidth - window.innerWidth
        // gsap.to('.cards', {
        //     x:-dist,
        //     ease:'none',
        //     scrollTrigger: {
        //         trigger:'.cards',
        //         start:'bottom bottom',
        //         end:'top top',
        //         scrub: 0.2
        //     }
        // })

        document.querySelectorAll('.layer').forEach(layer => {
            gsap.to(layer, {
                scaleY:0,
                duration:1.2,
                ease:'power4.inOut',
                scrollTrigger:{
                    trigger:layer,
                    start:'top bottom'
                }
            })
        })

        document.querySelectorAll('.trait').forEach(el => {
            gsap.to(el, {
                scaleX:1,
                duration:0.8,
                ease:'power4.inOut',
                scrollTrigger:{
                    trigger:el.parentNode,
                    start:'top bottom',
                    end:'bottom top',
                    toggleActions: 'play reverse play reverse',
                }
            })
        })


        // SWIPER
        if(window.innerWidth > 767){
            const swiper = new Swiper(".swiper-about", {
                slidesPerView: 3,
                spaceBetween: 0.016 * window.innerWidth,
                mousewheel: {
                    forceToAxis: true,
                  },
                // loop: true,
                // navigation: {
                //     nextEl: ".toNextSlide",
                //     prevEl: ".toPrevSlide",
                // },
                // normalizeSlideIndex: false,
                // breakpoints: {
                //     768: {
                //         slidesPerView: 3,
                //     }
                // }
            }); 
        }


        document.querySelector('.formNewsletter').addEventListener('submit', (e) => {
            e.preventDefault()

            const baseUrl = 'https://connect.mailerlite.com/api/subscribers'
            const apiKey = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMGIxNTc3ZDhlNDRlYzM0YjQ2NzM1YmI1Y2JiNmZkOWQ3Yjk5NDU5OTc2NjFiZWE2ZDdhZDljNTUzMDk3ODQ0NWMwMzcyNGRhMDA2YTcwNDIiLCJpYXQiOjE2ODcxODE3ODQuNjc3NzUyLCJuYmYiOjE2ODcxODE3ODQuNjc3NzU3LCJleHAiOjQ4NDI4NTUzODQuNjcxMjM3LCJzdWIiOiI0OTM3MDYiLCJzY29wZXMiOltdfQ.YoKv0auMFULj0Dwdsc6VZ2MeXAuBwJx6MFKIl0oPApt90tPSzIk1JRvehTRUh8pkyAhzLf7v6qm2CXpv-rGT8S64p8J6CiqtvVOvbi_0_yl2WWltxEAZI9iGq_YPZ2dgb0RhFiXRBgpEwsPYATod-Vl2EGH32jS_SewQULWuMJxsF_7NT3pYUy4bz4QVgmUokUQKErV7zcI2pfT3BAl9M0ukzhSIZZGgcDNKgtdSvVJt5ZhHuAkRWdvVuqRvCxWY-b5wbDc_YGygmIRj3zpLBGoMG7AIDG-0PHb_R7TbQwQDhqPtAm9vD0dKkJEgVExiV0_Jx5xffJ4tS1ejl4dxkZUx1H8Wtn3_YDGE_-6L5jLpKIfnby90k_D9W1Ev79975FWyRN1gBQeZoloHFeCGv143P3-lm8sBPeUHMYzXIfEpisG3r9LWxsj9nKQVI0RRN5it0Q_lxiBB0rPm0T3nc4T-YJyZk162keb9KVvdAlJUGbL1xvwvo4kVA8xoK0422yc5ZoXpk8iQtIJDZfPwTQcYIPCgHjF3vD0kyZMWgqjm0fGJnDolG95Q9iQpQOLFMyzJd5S4-5jTj_-kgRVbDZHJVX_7AntC8gc2bXyqkqMFkrZreU6bb12CMZ4ioxvnki2yRTXFVZNFDANCj0GlQ03SpEIaNf9UWqxZHtQQdsc';

            const subscriberData = {
              email: document.querySelector('.email').value,
              groups: ['90943656708540050']
            };

            // Send the POST request
            fetch(baseUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + apiKey

              },
              body: JSON.stringify(subscriberData),
            })
              .then(response => response.json())
              .then(data => {
                //console.log(data); // Response from the request
                // Process the response here
                document.querySelector('.email').value = ""
                document.querySelector('.email').setAttribute('placeholder', 'Thank you')
              })
              .catch(error => {
                console.error(error);
                // Handle any errors here
              });
        })

    }else if(document.body.classList.contains('newsletter-page')){

        document.querySelector('.formNewsletter').addEventListener('submit', (e) => {
            e.preventDefault()

            const baseUrl = 'https://connect.mailerlite.com/api/subscribers'
            const apiKey = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMGIxNTc3ZDhlNDRlYzM0YjQ2NzM1YmI1Y2JiNmZkOWQ3Yjk5NDU5OTc2NjFiZWE2ZDdhZDljNTUzMDk3ODQ0NWMwMzcyNGRhMDA2YTcwNDIiLCJpYXQiOjE2ODcxODE3ODQuNjc3NzUyLCJuYmYiOjE2ODcxODE3ODQuNjc3NzU3LCJleHAiOjQ4NDI4NTUzODQuNjcxMjM3LCJzdWIiOiI0OTM3MDYiLCJzY29wZXMiOltdfQ.YoKv0auMFULj0Dwdsc6VZ2MeXAuBwJx6MFKIl0oPApt90tPSzIk1JRvehTRUh8pkyAhzLf7v6qm2CXpv-rGT8S64p8J6CiqtvVOvbi_0_yl2WWltxEAZI9iGq_YPZ2dgb0RhFiXRBgpEwsPYATod-Vl2EGH32jS_SewQULWuMJxsF_7NT3pYUy4bz4QVgmUokUQKErV7zcI2pfT3BAl9M0ukzhSIZZGgcDNKgtdSvVJt5ZhHuAkRWdvVuqRvCxWY-b5wbDc_YGygmIRj3zpLBGoMG7AIDG-0PHb_R7TbQwQDhqPtAm9vD0dKkJEgVExiV0_Jx5xffJ4tS1ejl4dxkZUx1H8Wtn3_YDGE_-6L5jLpKIfnby90k_D9W1Ev79975FWyRN1gBQeZoloHFeCGv143P3-lm8sBPeUHMYzXIfEpisG3r9LWxsj9nKQVI0RRN5it0Q_lxiBB0rPm0T3nc4T-YJyZk162keb9KVvdAlJUGbL1xvwvo4kVA8xoK0422yc5ZoXpk8iQtIJDZfPwTQcYIPCgHjF3vD0kyZMWgqjm0fGJnDolG95Q9iQpQOLFMyzJd5S4-5jTj_-kgRVbDZHJVX_7AntC8gc2bXyqkqMFkrZreU6bb12CMZ4ioxvnki2yRTXFVZNFDANCj0GlQ03SpEIaNf9UWqxZHtQQdsc';

            const subscriberData = {
              email: document.querySelector('.email').value,
              groups: ['90943656708540050']
            };

            // Send the POST request
            fetch(baseUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + apiKey

              },
              body: JSON.stringify(subscriberData),
            })
              .then(response => response.json())
              .then(data => {
                //console.log(data); // Response from the request
                // Process the response here
                document.querySelector('.email').value = ""
                document.querySelector('.email').setAttribute('placeholder', 'Thank you')
              })
              .catch(error => {
                console.error(error);
                // Handle any errors here
              });
        })

    // single
    }else if(document.body.classList.contains('single')){

        cancelAnimationFrame(window.leRaf);

        // if(document.querySelector('video') !== null){
        //     new YouTubeToHtml5({
        //         withAudio: true
        //     });
        // }

        if(document.getElementById('garciScroll').classList.contains('evolt')){
            gsap.to('.trans', {
                scrollTrigger: {
                    trigger:'.fiveCards',
                    start:'top bottom',
                    end:'bottom top',
                    scrub:true
                },
                y:'0%',
                ease:"none"
            })
        }else if(document.getElementById('garciScroll').classList.contains('kard')){
            gsap.to('.content .fond svg', {
                scrollTrigger: {
                    trigger:'.fond',
                    start:'top bottom',
                    end:'bottom top',
                    scrub:true
                },
                scale:0.8,
                ease:"none"
            })
        }else if(document.getElementById('garciScroll').classList.contains('nabla')){
            if(window.innerWidth > 767){
                gsap.to('.content .paraS2', {
                    scrollTrigger: {
                        trigger:'.duo',
                        start:'top bottom',
                        end:'bottom top',
                        scrub:true
                    },
                    y:"-10%",
                    ease:"none"
                })
                gsap.to('.content .paraS', {
                    scrollTrigger: {
                        trigger:'.duo',
                        start:'top bottom',
                        end:'bottom top',
                        scrub:true
                    },
                    y:"-15%",
                    ease:"none"
                })
            }
        }else if(document.getElementById('garciScroll').classList.contains('spendesk')){
            gsap.to('.t1', {
                scrollTrigger: {
                    trigger:'.content .fond',
                    start:'top bottom',
                    end:'bottom top',
                    scrub:true
                },
                y:"-10%",
                ease:"none"
            })
            gsap.to('.t2', {
                scrollTrigger: {
                    trigger:'.content .fond',
                    start:'top bottom',
                    end:'bottom top',
                    scrub:true
                },
                y:"-20%",
                ease:"none"
            })
            gsap.to('.t3', {
                scrollTrigger: {
                    trigger:'.content .fond',
                    start:'top bottom',
                    end:'bottom top',
                    scrub:true
                },
                y:"-5%",
                ease:"none"
            })
            
        }else if(document.getElementById('garciScroll').classList.contains('shift')){
            if(window.innerWidth > 767){
                gsap.to('.content .paraS2', {
                    scrollTrigger: {
                        trigger:'.duo',
                        start:'top bottom',
                        end:'bottom top',
                        scrub:true
                    },
                    y:"-10%",
                    ease:"none"
                })
                gsap.to('.content .paraS', {
                    scrollTrigger: {
                        trigger:'.duo',
                        start:'top bottom',
                        end:'bottom top',
                        scrub:true
                    },
                    y:"-15%",
                    ease:"none"
                })
            }
        }

        if(!document.body.classList.contains('fromFiche')){
            gsap.to('#garciScroll h1 .haut', {
                duration:1, 
                y:'-100%',
                // delay:0.4,
                ease:"power4.inOut"
            })
            gsap.to('#garciScroll h1 .bas', {
                duration:1, 
                y:'0%',
                // delay:0.4,
                ease:"power4.inOut",
            })
        }
        document.body.classList.remove('.fromFiche')

        // Sélectionnez l'élément que vous voulez observer
        // let st, st2
        const elementToObserve = document.querySelector('.content');

        // Fonction de rappel pour l'observateur
        const callback = (entries, observer) => {
            entries.forEach(entry => {
                // scroll.resize()

                if(window.innerWidth > 767){
                    st && st.kill()
                    st = ScrollTrigger.create({
                        trigger:'#main',
                        start:'bottom bottom+=' + 0.6 * window.innerHeight,
                        end:'bottom bottom',
                        onEnter:() => {
                            // console.log('onEnter')
                            document.getElementById('projets').classList.add('actifFooter');
                            window.raf();
                        },
                        onLeaveBack:() => {
                            // console.log('onLeaveBack')
                            cancelAnimationFrame(window.leRaf);
                            document.getElementById('projets').classList.remove('actifFooter');
                        }
                    });

                    st2 && st2.kill()
                    st2 = gsap.timeline()

                    st2.fromTo('#projets', {
                        y:"100%"
                    },{
                        scrollTrigger:{
                            id:'projetsY',
                            trigger:'#main',
                            start:'bottom bottom+=' + 0.6 * window.innerHeight,
                            end:'bottom bottom',
                            scrub: true,
                        },
                        y:"30%",
                        // immediateRender:false,
                        ease:'none'
                    },0)

                    st2.fromTo('.innerExp', {
                        scale:1,
                        perspectiveOrigin: '50% 10%',
                    },{
                        scrollTrigger:{
                            id:'innerExpScale',
                            trigger:'#main',
                            start:'bottom bottom+=' + 0.6 * window.innerHeight,
                            end:'bottom bottom',
                            scrub: true,
                        },
                        perspectiveOrigin: '50% -65%',
                        y:0.045 * window.innerHeight + "px",
                        scale:0.658,
                        // immediateRender:false,
                        ease:'none'
                    },0)
                    ScrollTrigger.refresh()
                }else{
                    st && st.kill()
                    st = ScrollTrigger.create({
                        trigger:'.content',
                        start:'bottom 200%',
                        end:'bottom 40%',
                        onEnter:() => {
                            document.getElementById('projets').classList.add('actifFooter');
                            window.raf();

                            document.getElementById('projets').style.top = document.getElementById('main').offsetHeight - 400 + "px";
                            //document.getElementById('projets').style.height = window.innerHeight + "px";

                        },
                        onLeaveBack:() => {
                            cancelAnimationFrame(window.leRaf);
                            document.getElementById('projets').classList.remove('actifFooter');

                            document.getElementById('projets').style.top = "0px";
                        }
                    });
                    ScrollTrigger.refresh()
                }
            });
        };
        observer = new ResizeObserver(callback);
        observer.observe(elementToObserve);
        

        //
        // pour changer dynamiquement trop SALE SO FAR
        // 
        let currentPro = document.querySelector('.liste button.current');
        
        let nextPro;
        if(currentPro.nextElementSibling != null)
            nextPro = currentPro.nextElementSibling;
        else
            nextPro = document.querySelectorAll('.liste button')[0];

        simulateClick(nextPro);
        window.changementSlideSet(document.querySelector('.fiche.current'), -1);

        // NEW V2
        raf()
        document.getElementById('projets').classList.add('fake')
        gsap.delayedCall(0.6, () => {
            cancelAnimationFrame(leRaf)
            document.getElementById('projets').classList.remove('fake')
        })
        // FIN NEW V2
        //
        //
        //

    // join
    }else if(document.body.classList.contains('join')){
        
        window.webglJoin = new Webgl();
        window.webglJoin.initJoin();

        ScrollTrigger.create({
            trigger:'.innerCan',
            start:'top bottom',
            end:'bottom top',
            onUpdate: () => {
                if(window.webglJoin.materialP != undefined){
                    // OLD
                    // if(!isMobile()){
                    //     window.webglJoin.materialP.uniforms.uTranslate.value = -scroll.current/100;
                    // }else{
                    //     window.webglJoin.materialP.uniforms.uTranslate.value = window.scrollY/100;
                    // }
                    window.webglJoin.materialP.uniforms.uTranslate.value = window.scrollY/100;

                    window.webglJoin.renderPost(window.webglJoin.sceneJ, window.webglJoin.cameraJ);
                }
            }
        });

        // ScrollTrigger.create({
        //     trigger:'.kidding',
        //     start:'top top',
        //     end:'bottom bottom',
        //     pin:'.fixe',
        //     // onUpdate: (self) => {
        //     //     if(self.progress <= 0.33){
        //     //         window.currentStep = 0;
        //     //     }else if(self.progress > 0.33 && self.progress <= 0.66){
        //     //         window.currentStep = 1;
        //     //     }else if(self.progress > 0.66){
        //     //         window.currentStep = 2;
        //     //     }
        //     // }
        // });

        // gsap.to('#roulette', {
        //     scrollTrigger:{
        //         trigger:'.kidding',
        //         endTrigger:'.secQuatuor',
        //         start:'top bottom',
        //         end: 'top top',
        //         scrub:true,
        //         onEnter:() => {document.getElementById('roulette').classList.add('actif')},
        //         onLeaveBack:() => {document.getElementById('roulette').classList.remove('actif')},
        //         onLeave:() => {document.getElementById('roulette').classList.remove('actif')},
        //         onEnterBack:() => {document.getElementById('roulette').classList.add('actif')},
        //         // onUpdate:(self) => {
        //         //     if(self.progress > 0.5 && !document.querySelector('.antiRota3').classList.contains('actif')){
        //         //         document.querySelector('.antiRota3').classList.add('actif')
        //         //     }else if(self.progress <= 0.5 && document.querySelector('.antiRota3').classList.contains('actif')){
        //         //         document.querySelector('.antiRota3').classList.remove('actif')
        //         //     }
        //         // }
        //     },
        //     rotate:"-150deg",
        //     ease:'none',
        //     immediateRender:false
        // })
        // gsap.to('.antiRota', {
        //     scrollTrigger:{
        //         trigger:'.kidding',
        //         start:'top bottom',
        //         end: 'bottom top',
        //         scrub:true
        //     },
        //     rotate:"150deg",
        //     ease:'none'
        // })

        // ScrollTrigger.create({
        //     trigger:'.clair',
        //     start:'top top',
        //     end:'bottom bottom',
        //     onLeave: () => {
        //         document.getElementById('webgl').classList.remove('cache');
        //     },
        //     onEnterBack: () => {
        //         document.getElementById('webgl').classList.add('cache');
        //     }
        // });

        
    }else if(document.body.classList.contains('p404')){
        lenis.stop()
        window.webgl404 = new Webgl();
        window.webgl404.init404();

        if(!isMobile()){
            window.webgl404.wheel();
        }else{
            window.webgl404.touchmove();
        }
    }

    if(document.querySelector('.selected') !== null){
        ScrollTrigger.create({
            trigger:'.selected',
            start:'top 80%',
            onEnter: () => {
                gsap.killTweensOf('.logos svg');
                gsap.to('.logos svg', {
                    opacity:1,
                    duration:0.5,
                    stagger:0.08,
                    ease:"power4.out"
                })
            },
            onLeaveBack: () => {
                gsap.killTweensOf('.logos svg');
                gsap.set('.logos svg', {opacity:0});
            }

        });
    }
}



window.homeVideosDesktopToMobile = function () {
    let vid1 = document.getElementById('vid1');
    vid1.classList.add('vidMob');
    document.querySelector('.vid1Mob').appendChild(vid1);

    let vid2 = document.getElementById('vid2');
    vid2.classList.add('vidMob');
    document.querySelector('.vid2Mob').appendChild(vid2);

    let vid3 = document.getElementById('vid3');
    vid3.classList.add('vidMob');
    document.querySelector('.vid3Mob').appendChild(vid3);
}

// window.homeVideosMobileToDesktop = function () {
//     let vid1 = document.getElementById('vid1');
//     vid1.classList.remove('vidMob');
//     document.querySelector('.antiRota1').appendChild(vid1);

//     let vid2 = document.getElementById('vid2');
//     vid2.classList.remove('vidMob');
//     document.querySelector('.antiRota2').appendChild(vid2);

//     let vid3 = document.getElementById('vid3');
//     vid3.classList.remove('vidMob');
//     document.querySelector('.antiRota3').appendChild(vid3);
// }

window.mMove = function(e) {
    window.items.forEach(item => {
        if(item.el.classList.contains('actif')){
            item.xTo(gsap.utils.mapRange(0, item.el.clientWidth, -20, 20, e.clientX - item.el.getBoundingClientRect().left))
            item.yTo(gsap.utils.mapRange(0, item.el.clientHeight, -20, 20, e.clientY - item.el.getBoundingClientRect().top))
        }
    })
}