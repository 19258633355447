// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

// modules
// import {GSAPScroll} from "modules/GSAPScroll"
import {Transition} from "modules/Transition"
import {indexInParent, isMobile} from "modules/utils"
import {Webgl} from "modules/Webgl"

// constants
import {lenis, animsEntree} from 'modules/constants'


// gsap.config({
//     force3D: true
// })
let deltaY = 0, currentDelta = 0, isWheeling, ecart, animEnCours = false, animEnCoursP = false, pos = {}, oldPos = {}, nb_projet = 0, itIsMobile = false;
pos.x = 0;
pos.y = 0;
oldPos.x = 0;
oldPos.y = 0;
window.leRaf;
window.leRafPush;
window.velocity = 0;


gsap.registerPlugin(ScrollTrigger)

// let scroller;
// if(!isMobile()){
//     scroller = document.getElementById('garciScroll')
//     ScrollTrigger.defaults({
//         scroller: scroller
//     })
// }

const transition = new Transition();

window.addEventListener('resize', () => {
    if(document.body.classList.contains('home')){
        // if(document.querySelector('.vid1Mob video') == null && window.innerWidth < 768){
        //     homeVideosDesktopToMobile();
        // }

        // if(document.querySelector('#roulette video') == null && window.innerWidth > 767){
        //     homeVideosMobileToDesktop();
        // }
    }

    // on recharge la page quand on passe de mobile à deskt et inversement
    if(itIsMobile != isMobile()){
        document.location.reload();
    }

    // scroll.resize();
});

window.addEventListener('popstate', (e) => {
    transition.start(location.href);
}, false); 


window.addEventListener("DOMContentLoaded", () => {
    window.scrollTo(0,0);

    itIsMobile = isMobile();
    // console.log(itIsMobile);

    window.webgl = new Webgl(pos);
    window.webgl.init();

    // if(isMobile()){
    //     rafMobile();
    // }

    
    nb_projet = document.querySelectorAll('.fiche').length

    //
    document.querySelectorAll('.fiche').forEach(fiche => {
        fiche.addEventListener('mouseenter', ()=>{
            document.getElementById('explore').classList.add('actif');
            if(document.getElementById('projets').classList.contains('actifFooter')){
                document.querySelector('.current .toNext').classList.add('actif');
            }
        })
        fiche.addEventListener('mouseleave', ()=>{
            document.getElementById('explore').classList.remove('actif');
            document.querySelector('.current .toNext').classList.remove('actif');

        })
    })

    //
    document.querySelectorAll('.aimant').forEach(aimant => {
        aimant.addEventListener('mouseenter', () => {
            aimant.classList.add('actif')
        })
        aimant.addEventListener('mouseleave', () => {
            aimant.classList.remove('actif')
            gsap.to(aimant.querySelector('.suit'), {
                x:"0px",
                y:"0px",
                duration:0.2,
                ease:"power2.inOut"
            })
        })
    })

    document.addEventListener('click', function (event) {
        // console.log('animEnCours', animEnCours)
        // lien
        if (event.target.closest('.lien')) {
            event.preventDefault();
            if(!transition.animEnCours){

                transition.start(event.target.closest('.lien').getAttribute('href'));
                history.pushState({}, '', event.target.closest('.lien').getAttribute('href'));

                if(event.target.closest('.lien').classList.contains('fiche')){

                    cancelAnimationFrame(leRaf);

                    //
                    // if(isMobile()){
                    //     document.querySelectorAll('#projets .hero').forEach(x=>{
                    //         x.style.height = window.innerHeight + "px";
                    //     })
                    // }
                }
            }
        }else if(event.target.closest('.next')){
            deplacement(1);
        }else if(event.target.closest('.prev')){
            deplacement(-1);
        }else if(event.target.closest('.liste button') && !animEnCours){

            animEnCours = true;
            const el = event.target.closest('.liste button');
            const nb_projet_div = Math.round(document.querySelectorAll('.liste button').length / 2);
            const diff = Math.abs(indexInParent(document.querySelector('.liste .current')) - indexInParent(el));
            let nbFois = 0;

            if(indexInParent(el) < indexInParent(document.querySelector('.liste .current'))){
                if(diff >= nb_projet_div){
                    nbFois = document.querySelectorAll('.liste button').length - Math.abs(indexInParent(el) - indexInParent(document.querySelector('.liste .current')));
                }else{
                    nbFois = indexInParent(el) - indexInParent(document.querySelector('.liste .current'));
                }
            }else{
                if(diff >= nb_projet_div){
                    nbFois = -(document.querySelectorAll('.liste button').length - Math.abs(indexInParent(el) - indexInParent(document.querySelector('.liste .current'))));
                }else{
                    nbFois = indexInParent(el) - indexInParent(document.querySelector('.liste .current'));
                }
            }

            ecart = parseInt(20 - getTranslateZ(document.querySelectorAll('.fiche')[indexInParent(document.querySelector('.liste .current'))]));
            document.querySelectorAll('.fiche').forEach(x => {
                let temp = parseInt(x.getAttribute('data-z'));
                x.setAttribute('data-z', temp + ecart*nbFois);
            });
            setTimeout(() => {animEnCours = false},550)

        }else if(event.target.closest('.toMenu') && !animEnCours){
            animEnCours = true;
            document.querySelector('.toClose').style.display = "block";

            if(document.querySelector('#garciScroll .hero') !== null){
                gsap.to('#garciScroll .hero', {
                    height:window.innerHeight + "px",
                    ease:"power4.inOut",
                    duration:1.4
                })
            }

            gsap.to('.viewport', {
                // y:window.innerHeight + "px",
                opacity:0,
                ease:"power4.inOut",
                duration:1.4,
                onComplete:()=>{
                    animEnCours=false;
                    document.querySelector('.toMenu').style.display = "none"
                }
            })

            gsap.to('.toMenu', {
                duration:1.4,
                ease:"power4.inOut",
                y:"100%",
                opacity:0
            })
            gsap.to('.toClose', {
                duration:1.4,
                ease:"power4.inOut",
                y:"0%",
                opacity:1
            })

            gsap.delayedCall(1, () => {
                gsap.to('nav', {
                    opacity:1,
                    duration:1,
                    ease:"power4.out"
                })
            })

            const obj = {};
            obj.currentY = window.scrollY;
            gsap.to(obj, {
                currentY:0,
                onUpdate:()=>{
                    window.scrollTo(0, obj.currentY)
                },
                duration:1.4,
                ease:"power4.inOut"
            })

            // BLOQUEEEE
            document.body.classList.add('bloque');
            // document.body.style.height = window.innerHeight + "px";

            document.querySelector('nav').classList.add('actif');

        }else if(event.target.closest('.toClose') && !animEnCours){
            animEnCours = true;
            document.querySelector('.toMenu').style.display = "block";

            if(document.querySelector('#garciScroll .hero') !== null){
                gsap.to('#garciScroll .hero', {
                    height:"500px",
                    ease:"power4.inOut",
                    duration:1.4
                })
            }

            gsap.to('.viewport', {
                // y:"0px",
                opacity:1,
                ease:"power4.inOut",
                duration:1.4,
                onComplete:()=>{
                    animEnCours=false;
                    document.querySelector('nav').classList.remove('actif');
                    document.querySelector('.toClose').style.display = "none"
                }
            })

            gsap.to('.toMenu', {
                duration:1.4,
                ease:"power4.inOut",
                y:"0%",
                opacity:1
            })
            gsap.to('.toClose', {
                duration:1.4,
                ease:"power4.inOut",
                y:"-100%",
                opacity:0
            })

            gsap.to('nav', {
                opacity:0,
                duration:1,
                ease:"power4.out"
            })

            document.body.classList.remove('bloque');

        }else if(event.target.closest('.toProjets') && !animEnCoursP){
            animEnCoursP = true;

            //
            // if(isMobile()){
            //     document.querySelectorAll('#projets .hero').forEach(x=>{
            //         x.style.height = window.innerHeight + "px";
            //     })
            // }
            

            gsap.set('.fiche', {z:'0px'});
            document.querySelectorAll('.fiche.cache').forEach(el => {
                el.classList.add('off')
            })
            gsap.delayedCall(1.2, () => {
                document.querySelectorAll('.fiche.off').forEach(el => {
                    el.classList.remove('off')
                })
            })


            //gsap.set('#webgl', {opacity: 0});

            gsap.to('#main', {opacity:0, onComplete: () => {
                animEnCoursP = false;
                // scroll.destroy();
                if(lenis !== null){
                    lenis.stop()
                }

                document.getElementById('projets').setAttribute('style', '');
                document.getElementById('projets').classList.add('actif');


                if(window.innerWidth > 767){
                    gsap.fromTo('.innerExp', {
                        perspectiveOrigin: '50% 50%',
                        scale:0.6,
                        y:0.045 * window.innerHeight
                    },{
                        duration:0.7,
                        scale:0.658,
                        ease:"back.out"
                    });

                // quand format mobile
                }else{
                    gsap.fromTo('.innerExp', {
                        perspectiveOrigin: '50% 50%',
                        scale:0.6,
                        y:0.16 * window.innerHeight
                    },{
                        duration:0.7,
                        scale:0.75,
                        ease:"back.out"
                    });
                }

                gsap.to('#projets', {opacity:1});
                raf();

            }});
        }else if(event.target.closest('.innerC')){

            
            gsap.to('#projets', {opacity:0, onComplete: () => {

                ScrollTrigger.refresh();

                document.getElementById('projets').classList.remove('actif');
                gsap.to('#main', {opacity:1
                    //, onComplete:() => {gsap.set('#webgl', {opacity: 1})}
                });
                cancelAnimationFrame(leRaf);

                // if(!isMobile()){
                //     scroll.init();
                //     scroll.wheel();
                // }
                if(lenis !== null){
                    lenis.start()
                }
            }});
        }
    })
    
    window.addEventListener('wheel', function(e) {
        if(!document.querySelector('.fiche.off')){
            if(e.detlaY < -280 || e.detlaY >280){
                if(e.detlaY < -280){
                    deltaY = -280/120;
                }else if(e.detlaY > 280){
                    deltaY = 280/120;
                }
            }else{
                deltaY = e.deltaY/120;
            }
        }

        window.clearTimeout( isWheeling );
        isWheeling = setTimeout( (e) => {
            deltaY = 0;
        }, 66);
    }, {passive: true});

    let oldTouch = 0, deltaTouch = 0, firstMove = true;
    window.addEventListener('touchmove', (e) => {
        if(!document.getElementById('projets').classList.contains('actifFooter')){
            deltaTouch = e.touches[0].clientY - oldTouch;
            if(!firstMove && document.getElementById('projets').classList.contains('actif')){
                deltaY = deltaTouch/6;
            }
            firstMove = false;

            oldTouch = e.touches[0].clientY;

            window.clearTimeout( isWheeling );
            isWheeling = setTimeout( (e) => {
                deltaY = 0;
                deltaTouch = 0;
                oldTouch = 0;
                firstMove = true;
            }, 66);
        }
    }, {passive: true})

    // if(!isMobile()){
    //     ScrollTrigger.scrollerProxy(scroller, {
    //         scrollTop(value) {
    //             if (arguments.length) {
    //                 scroll.current = -value // setter
    //             }
    //             return -scroll.current // getter
    //         },
    //         getBoundingClientRect() {
    //             return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight}
    //         }
    //     });
    // }

    // projets
    //raf();
    

    // TEMP apres anim intro
    transition.start(window.location.href);

    // gsap.to('#main', {opacity:1, onComplete: () => {
    //     scroll.init();
    //     scroll.wheel();
    //     transition.animEnCours = false;

    //     animsEntree();
    //     console.log('dom ok 3', window.scrollY);
    // }});

    // if(document.body.classList.contains('single')){
    //     document.querySelectorAll('.innerBtn img').forEach(img => {
    //         img.setAttribute('src', '../img/chevron.svg')
    //     })

    //     document.querySelectorAll('#projets .fiche').forEach(fiche => {
    //         fiche.setAttribute('href', fiche.getAttribute('data-url'));
    //     })

    //     document.querySelector('.lienHome').setAttribute('href', '../');
    //     document.querySelector('.toAbout').setAttribute('href', '../about');
    //     document.querySelector('.toJoin').setAttribute('href', '../join');
    // }else{
    //     document.querySelectorAll('.innerBtn img').forEach(img => {
    //         img.setAttribute('src', 'img/chevron.svg')
    //     })
    // }


    //
    //
    // drag n drop about
    //
    //
    let timer, mouseIsHold = false, elmtDragged, xStart = 0, yStart = 0;
    const isHover = e => e.parentElement.querySelector(':hover') === e; 

    window.addEventListener("mousemove", (e) => {

        pos.x = e.x;
        pos.y = e.y;

        // if(mouseIsHold){
        //     console.log(e.x, e.y);
        //     gsap.set(elmtDragged, {
        //         x:e.x - xStart + parseInt(elmtDragged.getAttribute('oldX')) + "px",
        //         y:e.y - yStart + parseInt(elmtDragged.getAttribute('oldY')) + "px",
        //     });
        // }
    }, {passive: true});

    // window.addEventListener("mousedown", (e) => {
    
    //         xStart = e.x;
    //         yStart = e.y;
    //         document.querySelectorAll('.carte').forEach(x=>{
    //             if(isHover(x)){
    //                 elmtDragged = x;
    //                 x.classList.add('actif');
    //                 mouseIsHold = true;
    //             }
    //         });
    // });

    // window.addEventListener("mouseup", (e) => {

    //     if (timer){
    //         clearTimeout(timer); 
    //     }

    //     if(mouseIsHold){
    //         elmtDragged.classList.remove('actif');
    //         elmtDragged.setAttribute('oldX', gsap.getProperty(elmtDragged, "x"));
    //         elmtDragged.setAttribute('oldY', gsap.getProperty(elmtDragged, "y"));
    //         mouseIsHold = false;
    //     }
    // });
})



window.raf = function(){

    window.leRaf = requestAnimationFrame(raf);

    if(document.getElementById('projets').classList.contains('actif')){
        currentDelta += deltaY;
    }
    
    document.querySelectorAll('.fiche').forEach(x => {
        gsap.to(x, {
            duration:0.3,
            ease:"none",
            z: (-x.getAttribute('data-nb') * nb_projet * 20) + parseInt(x.getAttribute('data-z')) + parseInt(currentDelta) + "px"
        });

        // passage
        if(getTranslateZ(x) > 10 && !x.classList.contains('desc')){
            changementSlide(120, 10, x, -1);
        }else if(getTranslateZ(x) < -(nb_projet * 20 - 10) && !x.classList.contains('desc')){
            changementSlide(10, 120, x, 1);
        }

        // console.log(getTranslateY(x))
        if(x.classList.contains('cache') && getTranslateZ(x) > -(8 * 20 - 10)) {
            remonter(x, 10)
        }
        if(!x.classList.contains('cache') && getTranslateZ(x) < -(8 * 20 - 10) && !x.classList.contains('encours')) {
            descendre(x)
        }
    });

    if(document.getElementById('projets').classList.contains('actif') ){
        if(!itIsMobile){
            gsap.to('.fiche', { 
                duration:0.2,
                rotationY: (pos.x / window.innerWidth - 0.5)/2 + "deg", 
                rotationX: -(pos.y / window.innerHeight - 0.5)/2 - deltaY/5 * 1.5 + "deg", 
                ease:"none"
            });

            gsap.to('.innerExp', {
                duration:0.2,
                perspectiveOrigin: (-(pos.x / window.innerWidth - 0.5)*20 +50) + '% ' + (-(pos.y / window.innerHeight - 0.5)*20 - 65)+ '%',
                ease:"none"
            });
        }else{
            gsap.to('.innerExp', {
                duration:0.2,
                perspectiveOrigin: '50% ' + (-(pos.y / window.innerHeight - 0.5)*20 - 65)+ '%',
                ease:"none"
            });
        }
    }

    gsap.to('#explore', {
        x:pos.x + "px",
        y:pos.y + "px",
        duration:0.4,
        ease:'none'
    })


    gsap.to('.iEx div', {
        x:(oldPos.x - pos.x)*1.6 + "px",
        y:(oldPos.y - pos.y)*1.6 + "px",
        duration:0.3,
        ease:'none'
    })

    oldPos.x = pos.x;
    oldPos.y = pos.y;

    if(document.querySelector('.aimant.actif') != null){
        gsap.to('.aimant.actif .suit', {
            x: (pos.x - document.querySelector('.aimant.actif').getBoundingClientRect().left - document.querySelector('.aimant.actif').offsetHeight/2)/1.5 + "px",
            y: (pos.y - document.querySelector('.aimant.actif').getBoundingClientRect().top - document.querySelector('.aimant.actif').offsetWidth/2)/1.5 + "px",
            duration:0.2,
            ease:'none'
        })
    }
}  


window.rafPush = function(){
    window.leRafPush = requestAnimationFrame(rafPush);

    gsap.to('.push.actif img', {
        // rotationY: (pos.x / window.innerWidth - 0.5)*15 + "deg", 
        rotationY: gsap.utils.mapRange(
            document.querySelector('.push.actif').getBoundingClientRect().left,
            document.querySelector('.push.actif').offsetWidth + document.querySelector('.push.actif').getBoundingClientRect().left,
            -8,
            8, 
            pos.x) + "deg",
        rotationX: gsap.utils.mapRange(
            document.querySelector('.push.actif').getBoundingClientRect().top,
            document.querySelector('.push.actif').offsetHeight + document.querySelector('.push.actif').getBoundingClientRect().top,
            6,
            -6, 
            pos.y) + "deg",
        ease:"none",
        duration:0.2
    })
}

// window.directSlide = function() {
//     document.querySelectorAll('.fiche').forEach(x => {
//         gsap.set(x, {
//             z: (-x.getAttribute('data-nb') * nb_projet * 20) + parseInt(x.getAttribute('data-z')) + parseInt(currentDelta) + "px"
//         });

//         //console.log(getTranslateZ(x));

//         // passage
//         if(getTranslateZ(x) > 10 && !x.classList.contains('desc')){
//             changementSlide(120, 10, x, -1);
//         }else if(getTranslateZ(x) < -(nb_projet * 20 - 10) && !x.classList.contains('desc')){
//             changementSlide(10, 120, x, 1);
//         }

//         // passage
//         // if(getTranslateZ(x) > 10 && !x.classList.contains('desc')){
//         //     changementSlide(120, 10, x, -1);
//         // }else if(getTranslateZ(x) < -(nb_projet * 20 - 10) && !x.classList.contains('desc')){
//         //     changementSlide(10, 120, x, 1);
//         // }
//     });
// }

window.changementSlide = function(descente, montee, x, multi){

    x.classList.add('desc');
    gsap.to(x, {y: descente + '%', ease:"power2.in", duration:0.4, onComplete: function(e){

        x.setAttribute('data-nb', parseInt(x.getAttribute('data-nb')) - multi*1);
        x.setAttribute('data-index', parseInt(x.getAttribute('data-index')) + multi*document.querySelectorAll('.fiche').length);

        x.style.zIndex = x.getAttribute('data-index');

        gsap.killTweensOf(x);
        gsap.set(x, {z: (-x.getAttribute('data-nb') * nb_projet * 20) + parseInt(x.getAttribute('data-z')) + parseInt(currentDelta) + "px"});
        x.classList.remove('desc');

        //gsap.fromTo(x, {y: montee + '%'}, {y:0, ease:"power2.out", duration:0.4 }); 
        if(descente === 120){
            x.classList.add('cache')
        }else{
            x.classList.remove('cache')
            gsap.fromTo(x, {y: montee + '%'}, {y:0, ease:"power2.out", duration:0.4 });
        }
    }});

    const el = document.querySelector('.liste .current');
    el.classList.remove('current');

    const el2 = document.querySelector('.fiche.current');
    el2.classList.remove('current');

    if(multi == -1){
        if(el.nextElementSibling !== null){
            el.nextElementSibling.classList.add('current');
            el2.nextElementSibling.classList.add('current');
        }else{
            document.querySelectorAll('.liste button')[0].classList.add('current');
            document.querySelectorAll('.fiche')[0].classList.add('current');
        }
    }else{
        if(el.previousElementSibling !== null){
            el.previousElementSibling.classList.add('current');
            el2.previousElementSibling.classList.add('current');
        }else{
            document.querySelectorAll('.liste button')[document.querySelectorAll('.liste button').length - 1].classList.add('current');
            document.querySelectorAll('.fiche')[document.querySelectorAll('.fiche').length - 1].classList.add('current');
        }
    }
}

window.remonter = function(el, montee) {
    el.classList.remove('cache');

    gsap.fromTo(el, {
        y: montee + '%'
    },{
        y:0, ease:"power2.out", duration:0.4
    })
}

window.descendre = function(el, montee) {
    el.classList.add('encours');
    gsap.to(el, {
        y:'10%', ease:"power2.in", duration:0.4,
        onComplete:() => {
            el.classList.add('cache')
            el.classList.remove('encours');
        }
    })
}

window.changementSlideSet = function(x, multi){

    x.classList.add('desc');
    //gsap.set(x, {y: descente + '%', ease:"power2.in", duration:0.4, onComplete: function(e){
        
    x.setAttribute('data-nb', parseInt(x.getAttribute('data-nb')) - multi*1);
    x.setAttribute('data-index', parseInt(x.getAttribute('data-index')) + multi*document.querySelectorAll('.fiche').length);
    x.style.zIndex = x.getAttribute('data-index');

    gsap.killTweensOf(x);
    gsap.set(x, {z: (-x.getAttribute('data-nb') * nb_projet * 20) + parseInt(x.getAttribute('data-z')) + parseInt(currentDelta) + "px"});
    x.classList.remove('desc');

        //gsap.fromTo(x, {y: montee + '%'}, {y:0, ease:"power2.out", duration:0.4 }); 
        
    //}});

    const el = document.querySelector('.liste .current');
    el.classList.remove('current');

    const el2 = document.querySelector('.fiche.current');
    el2.classList.remove('current');

    if(multi == -1){
        if(el.nextElementSibling !== null){
            el.nextElementSibling.classList.add('current');
            el2.nextElementSibling.classList.add('current');
        }else{
            document.querySelectorAll('.liste button')[0].classList.add('current');
            document.querySelectorAll('.fiche')[0].classList.add('current');
        }
    }else{
        if(el.previousElementSibling !== null){
            el.previousElementSibling.classList.add('current');
            el2.previousElementSibling.classList.add('current');
        }else{
            document.querySelectorAll('.liste button')[document.querySelectorAll('.liste button').length - 1].classList.add('current');
            document.querySelectorAll('.fiche')[document.querySelectorAll('.fiche').length - 1].classList.add('current');
        }
    }
}







// utils fiche projet
function getTranslateZ(el) {
    var style = window.getComputedStyle(el);
    var matrix = new WebKitCSSMatrix(style.webkitTransform);
    return matrix.m43;
} 
function deplacement(sens){
    if(!animEnCours){
        animEnCours = true;
        ecart = parseInt(20 - sens*getTranslateZ(document.querySelectorAll('.fiche')[indexInParent(document.querySelector('.liste .current'))]));
        document.querySelectorAll('.fiche').forEach(x => {
            let temp = parseInt(x.getAttribute('data-z'));
            x.setAttribute('data-z', temp + sens*ecart);
        });
        setTimeout(function(){
            animEnCours = false;
        }, 550);
    }
}
document.addEventListener('keydown', function(event) {
    const key = event.key;
    if(key == "ArrowDown"){
        deplacement(1);
    }else if(key == "ArrowUp"){
        deplacement(-1);
    }
});



// TEMP
// document.addEventListener('keypress', logKey)
// function logKey(e) {
//     if(e.code=='Space') {
//         e.preventDefault();
//         if(document.querySelector('body').classList.contains('dev')) document.querySelector('body').classList.remove('dev');
//         else document.querySelector('body').classList.add('dev');
//     }
// }








// let oldHeight = 0;
// function rafMobile() {
//     requestAnimationFrame(rafMobile);

//     if(oldHeight != window.innerHeight){
//         oldHeight = window.innerHeight;

//         console.log('PAR LA', window.innerHeight, document.getElementById('projets').style.top)

//         // document.getElementById('projets').style.top = document.getElementById('main').offsetHeight - window.innerHeight + "px";
//         //document.getElementById('projets').style.height = window.innerHeight + "px";

//     }
// }

